import axios from 'axios';
import { getBaseUrl } from "./../utils/url.utils";
import { proxyPath } from './../utils';
const url = getBaseUrl();

export const createDynamicTag = (body,params) => {
    return axios.post(
        `https://${url}/ext/${proxyPath}/application/v1/cart/create?uid=${params?.uid}&buynow=${params?.buynow??''}`,
        body
    );
}

export const updateDynamicTag = (body,params) => {
    return axios.put(
        `https://${url}/ext/${proxyPath}/application/v1/cart/update?id=${params?.cart_id}&uid=${params?.uid}&buynow=${params?.buynow??''}`,
        body
    );
}

export const deleteDynamicTag = (cart_id,uid) => {
    return axios.delete(
        `https://${url}/ext/${proxyPath}/application/v1/cart/delete?id=${cart_id}&uid=${uid}`
    );
}
export const getCartInfo = (cart_id,buynow) =>{
    return axios.get(
        `https://${url}/ext/${proxyPath}/application/v1/cart/details?cart_id=${cart_id}&buynow=${buynow??''}`
    );
}