import axios from 'axios';
import { getBaseUrl } from "./../utils/url.utils";
import { proxyPath } from './../utils';
const url = getBaseUrl();


export const balanceEnquiry = (body, cartID="") => {
    return axios.post(
        `https://${url}/ext/${proxyPath}/application/v1/qwikcilver/balance-enquiry?cart_id=${cartID}`,
        body
    );
}

export const validateRedeem = (body) => {
    return axios.post(
        `https://${url}/ext/${proxyPath}/application/v1/qwikcilver/validate-redeem`,
        body
    );
}

export const redeemCard = (cartInfo, body) => {
    return axios.post(
        `https://${url}/ext/${proxyPath}/application/v1/qwikcilver/redeem?cart_id=${cartInfo.cart_id}&uid=${cartInfo?.uid}`,
        body
    );
}

export const checkRedeem = (cartID) => {
    return axios.get(
        `https://${url}/ext/${proxyPath}/application/v1/qwikcilver/check-redeem?cart_id=${cartID}`
    );
}

export const cancelRedeem = (id) => {
    return axios.post(
        `https://${url}/ext/${proxyPath}/application/v1/qwikcilver/cancel?id=${id}`
    );
}
export const reverseRedeem = (id) => {
    return axios.post(
        `https://${url}/ext/${proxyPath}/application/v1/qwikcilver/reverse?id=${id}`
    );
}
