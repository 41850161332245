<template>
  <div v-if="showWidget" :class="disable ? 'disable-giftcard' : ''">
    <div :key="cartRedemptionRef" v-if="giftCardRedemptionActive">
      <fdk-cart class="product__actions" ref="cart">
        <template slot-scope="cart">
          <button style="display: none" @click="handleReload(cart)">
            Reload
          </button>
        </template>
      </fdk-cart>
      <div class="redeem-dialog">
        <div class="vertical-center">
          <div class="card-img">
            <img src="./../../assets/card.svg" alt="close" />
          </div>
          <div class="redeem-content">
            <div class="redeem-heading">
              Have a Gift Card ?
            </div>    
            <div class="redeem-text">
              {{
                "Redeem Points To Save More"
              }}
            </div>
          </div>
        </div>
        <div class='apply-remove-btns'>
          <div>
            <button class="click-remove-button" @click="removeGift" :disabled="totalBalance > 0 ? false : true"  >
              X
            </button>
          </div>
          <div>
            <button :disabled="disable || cartValueZero" @click="openDialog" class="click-apply-button">
              APPLY GIFTCARD
            </button>
          </div>
        </div>
      </div>

      <Modal
        title="Redeem Card Points"
        @close="closeDialog"
        v-if="show"
        :show="show"
      >
        <template slot="body">
          <div v-if='!isRemove'>
            <CheckBalance
              :errorMessage="errorMessage"
              @handleCheck="handleCheck"
              v-show="!checked"
              :apiCall="apiCall"
            />
            <ApplyBalance
              :cardNumber="cardNumber"
              :balance="fetchedBalance"
              @handleApply="handleApply"
              v-show="checked"
              :buttonType="buttonText"
              :apiCall="apiCall"
            />
          </div>
          <div v-if='isRemove'>
              <ApplyBalance
              :balance="totalBalance"
              @handleApply="handleApply"
              :buttonType="buttonText"
              :apiCall="apiCall"
              :multiCards="cardList"
              :isCancel="isRemove"
              :totalRedeemBalance="totalBalance"
            />
          </div>
        </template>
      </Modal>
      <div :v-if="applied && totalBalance" class="success-popup">
        <SuccessModal
          :show="applied"
          :balanceValue="redeemAmount"
          @closeSucessModal="handleCloseSuccess"
        />
      </div>
    </div>
    <div v-else></div>
  </div>
</template>
<script>
import CheckBalance from "./checkBalance.vue";
import ApplyBalance from "./applyBalance.vue";
import SuccessModal from "./successModal.vue";
import Modal from "./../modal/Model.vue";

import {
  balanceEnquiry,
  redeemCard,
  checkRedeem,
  cancelRedeem,
  validateRedeem
} from "../../services/qwikcilver-core.service";
// import { getSalt } from "./../../services/encryption.service";
import {
  getCartId,
  isThisBuyNow,
  setZeroValueCheckout,
} from "./../../utils";

import {
  createDynamicTag,
  deleteDynamicTag,
  getCartInfo,
  updateDynamicTag,
} from "../../services/cart.service";
import { getRedeemSettings } from "../../services/settings.service";

import {
  TransactionTypeId,
  InputType,
  NumberOfCards,
  TagType,
} from "../../constants/cart.constants";
import { CalculateDiscount } from "./../../utils/discount-calculation.utils";
import { getEnvs } from "./../../services/env.service";
export default {
  name: "balance-redeemption",
  components: {
    CheckBalance,
    ApplyBalance,
    SuccessModal,
    Modal,
  },
  data() {
    return {
      cardNumber: "",
      cardPin: "",
      checked: false,
      applied: false,
      show: false,
      buttonText: "APPLY",
      totalBalance: 0,
      articleList: [],
      userData: null,
      cartData: null,
      existingRedeemData: null,
      ifExistingRedeemExist: false,
      fetchedBalance: null,
      cartRedemptionRef: 0,
      redeemAmount: 0,
      giftCardRedemptionActive: false,
      rowID: "",
      disable: true,
      toastMessage: "",
      errorMessage: false,
      toastType: "",
      showMsg: false,
      apiCall: false,
      uid: false,
      user: null,
      cartValue: 0,
      isMultiCard : false,
      cartList : [],
      isRemove : false,
      cartValueZero:false
    };
  },

  async mounted() {
    window.FPI.event.on("cart.update", this.handleUpdateCartEvent);
    window.FPI.event.on("cart.remove", this.handleUpdateCartEvent);
    window.FPI.state.user.subscribe((data) => {
      if (data && data.user) {
        this.user = data.user;
      }
    });
    if (window.location.pathname == "/cart/checkout") {
      this.disable = true;
    }
    let params = new URL(window.location).searchParams;
    let cart_id = getCartId();
    let cartInfo = await getCartInfo(cart_id, isThisBuyNow());
    let cartData = cartInfo?.data?.data;
    if (cartInfo?.data?.status == "success" && cartInfo?.data?.data) {
      this.cartValue = cartData?.breakup_values?.raw?.total || 0;
    }
    if (
      window.location.pathname == "/cart/checkout" &&
      params.get("cart_id") &&
      params.get("address_id") &&
      params.get("billing_address_id") &&
      params.get("reviewed") &&
      this.cartValue
    ) {
      this.disable = false;
    }
    if (
      window.location.pathname == "/cart/checkout" &&
      (!params.get("cart_id") ||
        !params.get("address_id") ||
        !params.get("billing_address_id") ||
        !params.get("reviewed"))
    ) {
      this.disable = true;
    }
    if (window.location.pathname == "/cart/payment-info" && this.cartValue) {
      this.disable = false;
    }
    await this.isGiftCardRedemptionActive();

    this.existingRedeemData = await checkRedeem(cart_id);

    if (
      this.existingRedeemData?.data?.status == "success" &&
      this.existingRedeemData?.data?.data
    ) {
      this.ifExistingRedeemExist = true;
      this.totalBalance = this.existingRedeemData?.data?.data?.amount;
      this.cardNumber = this.existingRedeemData?.data?.data?.card_number;
      this.cardList = [...this.existingRedeemData?.data?.data?.card_number];
      this.rowID = this.existingRedeemData?.data?.data?.row_id;
      this.uid = this.existingRedeemData?.data?.data?.uid;
      this.isMultiCard = true;
    }
    let response = await getEnvs();
    setZeroValueCheckout(response?.data?.ZERO_VALUE_CHECKOUT);
  },

  watch: {
    show() {
      if (this.show) {
        document.body.classList.add("prevent-scroll");
      } else {
        document.body.classList.remove("prevent-scroll");
      }
    },
    $route: function (to) {
      let queryParam = Object.assign({}, to.query);
      if (
        queryParam.billing_address_id &&
        queryParam.cart_id &&
        queryParam.address_id &&
        queryParam.reviewed &&
        window.location.pathname == "/cart/checkout" &&
        this.cartValue
      ) {
        this.disable = false;
      } else {
        this.disable = true;
      }
      if (window.location.pathname == "/cart/payment-info" && this.cartValue) {
        this.disable = false;
      }
    },
  },
  methods: {
    /**
     * cart event
     */

    async handleUpdateCartEvent() {
      this.handleApply(true);
    },

    /**
     * Method to trigger show dialog
     */
    openDialog() {
      this.isRemove = false;
      if (!this.disable) {
        this.show = true;
      }
    },
    removeGift(){
      this.show = true;
      this.buttonText = "REMOVE";
      this.isRemove = true;
      this.cardNumber = "";
      this.cardPin = "";
      this.fetchedBalance = 0;
      this.isMultiCard = false;
    },
    /**
     * Method to trigger close dialog
     */
    closeDialog() {
      if (this.apiCall) {
        return;
      }
      this.cardNumber = "";
      this.cardPin = "";
      this.show = false;
      this.checked = false;
      this.buttonText = "APPLY"
    },

    forceRerender() {
      this.cartRedemptionRef += 1;
    },

    /**
     * Method to trigger balance enquiry
     * @param {*} value : Values received from checkBalance.vue on CardNumber and CardPin applied.
     */
    async handleCheck(value) {
      try {
        this.cardNumber = value.cardNumber;
        this.cardPin = value.cardPin;
        if (value.cardNumber && value.cardPin) {
          this.balanceCheck();
        } else {
          let msg = !value.cardNumber ? "Card Number" : "Card Pin";
          this.errorMessage = `Please provide ${msg} `;
        }
      } catch (error) {
        console.error(error?.message);
      }
    },

    /**
     *
     * @param {*}
     */
    async handleApply(isRemove) {
      try {
        if (!isRemove) {
          await this.applyGiftCard();
        } else {
          await this.cancelRedeem();//TODO make cardList = [] after cancellation
          this.closeDialog();
          this.forceRerender();
          this.$forceUpdate();
          this.totalBalance = 0;   
          this.cartValueZero = false;       
        }
      } catch (error) {
        console.log("error", error);
        console.error(error?.message);
      }
    },

    /**
     * Method to handle close and success flags
     */
    async handleCloseSuccess() {
      this.applied = false;
      this.show = false;
      // this.buttonText = "REMOVE";
      await this.handleReload();
    },

    /**
     * Applying the qwikcilver giftcard with redeem
     */
    async applyGiftCard() {
      try {
        let cart_id = getCartId();
        let cartInfo = await getCartInfo(cart_id, isThisBuyNow());
        let cartData = cartInfo?.data?.data;
        let cartValue = cartData?.breakup_values?.raw?.total;
        if (cartInfo?.data?.status == "success" && cartInfo?.data?.data) {
          this.apiCall = true;
          let validateBody = {
                "TransactionTypeId": 302,
                "InputType": 1,
                Cards: [
                  {
                    CardNumber: this.cardNumber,
                    CardPin: this.cardPin,
                    Amount: cartValue < this.fetchedBalance ? cartValue - 1 : this.fetchedBalance
                  },
                ],
                "CurrencyCode": "INR",
                "BillAmount": cartData?.breakup_values?.raw?.total,
                "InvoiceNumber": null,
                "Notes": cart_id
            };
          let validateRedeemResponse = await validateRedeem(validateBody);
          console.log('validateRedeemResponse:', validateRedeemResponse)
          if(validateRedeemResponse.data.status == "success"){
            
            if(this.cardList && this.cardList?.length >= 1){
              let alreadyAppliedValue = cartData?.breakup_values?.display.map((x)=>x["display"]=='Giftcard'?x["value"]:0).reduce((ac,cur)=>ac+cur,0);
              // summing up the alreadyAppliedValue because it will be negative value
              cartValue = cartValue + alreadyAppliedValue;
              this.isMultiCard = true;
            }
            this.redeemAmount =  cartValue < this.fetchedBalance ? cartValue - 1 : this.fetchedBalance;
            if(this.cardList?.length && this.cardList?.indexOf(this.cardNumber)== -1){
              this.cardList.push(this.cardNumber);
            }
            if(!this.cardList?.length){
              this.cardList=[this.cardNumber];
            }
            let reqBody = {
              cardList :this.cardList ,
              TransactionTypeId: TransactionTypeId.REDEEM,
              InputType: InputType,
              Cards: [
                {
                  CardNumber: this.cardNumber,
                  CardPin: this.cardPin,
                  Amount: this.redeemAmount,
                },
              ],
              numberOfCards: NumberOfCards,
              BillAmount: this.redeemAmount,
            };
            let params = {
              cart_id,
              isMultiCard:'no'
            }
            if(this.uid){
              params["uid"]=this.uid 
            }
            let response = await redeemCard(params, reqBody);
            
            this.rowID = response?.data?.data.row_id;
            if(!this.uid){
              this.uid = response?.data?.data.uid;
            }
            this.collectArticles(cartData?.items);
            setTimeout(() => (this.totalBalance += this.redeemAmount), 200);
            const tagResponse = this.isMultiCard  ? await  this.updateDynamicTag(this.uid) : await  this.createDynamicTag(this.uid);
            if (tagResponse.data.success) {
              this.applied = true;
              this.checked = false;
              this.cardNumber = "";
              this.cardPin = "";
              // this.isMultiCard = false;
            }
          }else{
            this.errorMessage = validateRedeemResponse.data.error;
          }
          this.apiCall = false;
        }
      } catch (error) {
        this.apiCall = false;
        console.error(error?.message);
      }
    },

    async balanceCheck() {
      try {
        this.errorMessage = false;
        this.apiCall = true;
        let reqBody = {
          TransactionTypeId: TransactionTypeId.BALANCE_ENQUIRY,
          InputType: InputType,
          Cards: [
            {
              CardNumber: this.cardNumber,
              CardPin: this.cardPin,
            },
          ],
        };
        let cart_id = getCartId();
        const response = await balanceEnquiry(
          {
            data: reqBody,
          },
          cart_id
        );
        this.fetchedBalance = response?.data?.data?.balance;
        this.apiCall = false;
        if (!response?.data?.data?.balance) {
          this.errorMessage = `Card Balance is ${response?.data?.data?.balance}`;
        } else {
          if (this.fetchedBalance > 0) {
            this.checked = true;
          }
          this.toastMessage = "Successfully fetched the balance";
          this.toastType = "success";
          this.showMsg = true;
          this.errorMessage = false;
        }
      } catch (error) {
        this.apiCall = false;
        this.errorMessage =
          error?.response?.data?.data?.message == "Validation Failed."
            ? "Validation Failed !"
            : error?.response?.data?.data?.message;
      }
    },
    async encryptPayload(payload, publicKey) {
      try {
        // Convert the payload to ArrayBuffer
        const encoder = new TextEncoder();
        const data = encoder.encode(payload);

        // Import the public key
        const publicKeyObject = await crypto.subtle.importKey(
          "spki",
          publicKey,
          {
            name: "RSA-OAEP",
            hash: "SHA-256",
          },
          false,
          ["encrypt"]
        );

        // Encrypt the data
        const encryptedData = await crypto.subtle.encrypt(
          {
            name: "RSA-OAEP",
          },
          publicKeyObject,
          data
        );

        // Convert encrypted data to base64
        const encryptedDataBase64 = btoa(
          String.fromCharCode(...new Uint8Array(encryptedData))
        );
        return encryptedDataBase64;
      } catch (error) {
        console.error("Encryption error:", error);
        throw error;
      }
    },
    async updateDynamicTag(uid){
      try{
        let tagBody = {
          cart_id: getCartId(),
          cardList : [...this.cardList],
          cardNo:this.cardNumber
        };
        let tagResponse = await updateDynamicTag(tagBody, {
          uid,
          cart_id: getCartId(),
          buynow: isThisBuyNow(),
        });
        if (tagResponse.data.data.success) {
          // if the cart response is true then we will close the dialogue
          this.closeDialog();
        }
        return tagResponse.data;
      }catch(error){
        console.error(error?.message);
        return false;
      }
    },
    async createDynamicTag(uid) {
      try {
        let tagBody = {
          cart_id: getCartId(),
          cardList : [...this.cardList],
          cardNo:this.cardNumber
        };
        const tagResponse = await createDynamicTag(tagBody, {
          uid,
          buynow: isThisBuyNow(),
        });
        if (tagResponse.data.data.success) {
          // if the cart response is true then we will close the dialogue
          this.closeDialog();
        }
        return tagResponse.data;
      } catch (error) {
        console.error(error?.message);
        return false;
      }
    },
    async handleReload() {
      if (this.$refs["cart"]) {
        await this.$refs["cart"].getCart();
      } else {
        window.location.reload();
      }
    },
    collectArticles(cartItems) {
      try {
        let articleListRef = [];
        let articleValues = cartItems?.map((item) => {
          return item?.price?.base?.selling;
        });
        let discounts = CalculateDiscount(articleValues, this.redeemAmount);
        cartItems?.forEach((item, i) => {
          let article = {
            article_id: item?.article?.uid,
            value: parseInt(discounts[i] * 100) / 100,
            code: "giftcard",
            type: TagType.MOP,
            meta: {},
          };
          articleListRef.push(article);
        });
        this.articleList = articleListRef;
      } catch (error) {
        console.error(error?.message);
      }
    },

    async cancelRedeem() {
      try {
        this.apiCall = true;
        let cart_id = getCartId();
        await cancelRedeem(this.rowID);
        this.totalBalance = 0;
        this.checked = false;
        this.show = false;
        this.applied = false;
        this.buttonText = "APPLY";
        this.redeemAmount = 0;
        this.rowID = false;
        await deleteDynamicTag(cart_id, this.uid);
        this.apiCall = false;
        this.uid = false;
        this.cardList = [];
        this.isMultiCard = false;
        this.fetchedBalance = 0;
        this.handleReload();
      } catch (error) {
        this.apiCall = false;
        console.error(error?.message);
      }
    },
    async isGiftCardRedemptionActive() {
      try {
        const response = await getRedeemSettings();
        if (response?.data?.data?.active) {
          this.giftCardRedemptionActive = true;
        } else {
          this.giftCardRedemptionActive = false;
        }
      } catch (error) {
        console.error("ERROR_ISGIFTCARDREDEMPTIONACTIVE", error?.message);
      }
    },
  },
  computed: {
    showWidget: function () {
      const buynow = isThisBuyNow();
      return !!this.user && !buynow;
    },
  },
};
</script>
<style>
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600&display=swap");

.success-popup .modal-header {
  display: none;
}

.success-popup .modal-mask {
  justify-content: center;
  align-items: center;
}

.success-popup .modal-container {
  width: 312px;
  min-height: max-content;
  /* padding: 24px; */
}

.redeem-dialog {
  position:relative;
  background: #fff;
  justify-content: space-between;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 24px;
  gap: 16px;
  width: 100%;
  border-top: 1px solid #f0f0f0;
  border-bottom: 1px solid #f0f0f0;
}

.redeem-content .redeem-heading {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 17px;
  text-transform: uppercase;
  color: #000000;
}
.card-img {
  margin-right: 10px;
}
.redeem-content .redeem-text {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 17px;
  text-align: center;
  text-transform: capitalize;
  color: #4d4d4e;
}
.vertical-center {
  display: flex;
  justify-content: center;
  align-items: center;
}
.prevent-scroll {
  overflow: hidden !important;
}
.click-apply-button {
  color: var(--linkColor, #48e868);
  cursor: pointer;
  font-weight: 700;
  font-size: 12px;
  cursor: pointer;
  border-radius: 4px;
  border: none;
  background: transparent;
}
.click-remove-button{
  background: var(--linkColor, #de825a);
  position: absolute;
  color: #fff;
  width: 23px;
  margin-left: -36px;
  margin-top: -1px;
  border-radius: 50px;
  font-weight: 700;
  font-size: 12px;
  cursor: pointer;
}
.click-remove-button:hover{
  opacity: 0.9;
}
.disable-giftcard {
  opacity: 0.5;
  pointer-events: none;
}
.click-remove-button:disabled{
  background: var(--linkColor, #de825a);
  cursor: not-allowed;
  display: none;
}
.click-apply-button:disabled{
  background: transparent;
}
</style>
